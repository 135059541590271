import {
  Table, Thead, Tbody, Tr, Th, Td, Box, Text, Link,
} from '@chakra-ui/react';

function SourcesTable() {
  return (
    <Box background="white" p={4} borderRadius={4}>
      <Text fontSize="xs">Performance results and evidence are segmented into a headlines area and eleven separate zones.</Text>
      <Text fontSize="xs">Most of this information is already available in various places across the sector, but is not easily accessible or easy to understand.</Text>
      <Text fontSize="xs">The RCA Reports and Dashboard are the first time we have a national, objective picture of transport investment and performance collated into a single resource.</Text>
      <Text as="h2" fontSize="2xl" my={4}>Headlines - Summary of key facts from the RCA report</Text>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>REPORT ELEMENT</Th>
            <Th>NOTES</Th>
            <Th>SOURCE</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td><strong>Population</strong></Td>
            <Td>
              The latest estimated resident population for the Territorial Authority, at 30 June. The latest population estimate reported may not be for the reported financial year.
            </Td>
            <Td>
              Statistics New Zealand
              {' '}
              <Link href="https://www.stats.govt.nz/topics/population-estimates-and-projections" isExternal>
                Subnational Population Estimates
              </Link>
              .
              <br />
              Data sourced from the
              {' '}
              <Link href="https://www.stats.govt.nz/tools/aotearoa-data-explorer/" isExternal>
                Statistics New Zealand
                {' '}
                <i>NZStat</i>
                {' '}
                web tool
              </Link>
              .
            </Td>
          </Tr>

          <Tr>
            <Td><strong>GDP ($M)</strong></Td>
            <Td>
              The latest annual nominal Gross Domestic Product (GDP) for the Territorial Authority, provided by MBIE. The reported GDP is estimated or provisional and may not be for the reported financial year.
            </Td>
            <Td>
              Statistics New Zealand
              {' '}
              <Link href="https://www.stats.govt.nz/topics/gross-domestic-product/" isExternal>
                Regional GDP
              </Link>
              {' '}
              and MBIE
              {' '}
              <Link href="https://www.mbie.govt.nz/business-and-employment/economic-development/regional-economic-development/modelled-territorial-authority-gross-domestic-product" isExternal>
                Modelled Territorial Authority Gross Domestic Product (MTAGDP)
              </Link>
              .
              <br />
              Data sourced from the
              {' '}
              <Link href="http://webrear.mbie.govt.nz/summary/new-zealand" isExternal>
                MBIE Regional Economic Activity Tool
              </Link>
              .
            </Td>
          </Tr>

          <Tr>
            <Td><strong>Total 2021-24 NLTP Budget ($M)</strong></Td>
            <Td>
              The total 3-year 2021-24 NLTP budget for the Approved Organisation, including both the NZTA and local share to provide a safe, accessible, efficient, and connected transport system.
            </Td>
            <Td>
              Budget sourced from
              {' '}
              <Link href="https://www.nzta.govt.nz/planning-and-investment/national-land-transport-programme/2018-21-nltp/nltp-snapshot-and-tables/regional-and-activity-tables" isExternal>
                NZTA Planning and investment, National Land Transport Programme, 2021-24 NLTP, NLTP snapshot and tables
              </Link>
              .
            </Td>
          </Tr>

          <Tr>
            <Td><strong>Valuation ($M)</strong></Td>
            <Td>
              The roading asset depreciated replacement cost valuation. Reported in NZD millions.
            </Td>
            <Td>
              Published in the Territorial Authority annual report for the financial year. Sourced from the Territorial Authority website.
            </Td>
          </Tr>

          <Tr>
            <Td><strong>Expenditure ($M)</strong></Td>
            <Td>
              The financial year&apos;s total transport-related expenditure co-invested by NZTA (NZTA share) and Approved Organisations (local share). Excludes transport-related expenditure fully funded by the Territorial Authority or others.
            </Td>
            <Td>
              Sourced from
              {' '}
              <Link href="https://www.nzta.govt.nz/planning-and-investment/learning-and-resources/transport-data/data-and-tools/" isExternal>
                NZTA Data and Tools
              </Link>
              .
            </Td>
          </Tr>

          <Tr>
            <Td><strong>Expenditure per capita ($)</strong></Td>
            <Td>
              The financial year&apos;s transport-related expenditure per resident. Calculated by dividing the total co-invested expenditure by the resident population estimate. Reported as NZD per resident person.
            </Td>
            <Td>
              Calculated from
              {' '}
              <Link href="https://www.nzta.govt.nz/planning-and-investment/learning-and-resources/transport-data/data-and-tools/" isExternal>
                NZTA Data and Tools
              </Link>
              {' '}
              and Statistics New Zealand
              {' '}
              <Link href="https://www.stats.govt.nz/topics/population-estimates-and-projections" isExternal>
                Subnational Population Estimates
              </Link>
              .
            </Td>
          </Tr>

          <Tr>
            <Td><strong>Total Roads (km)</strong></Td>
            <Td>
              The length of road reported in centreline kilometres at the end of the financial year. Reported to NZTA by the Territorial Authority for annual reporting. Amounts may vary slightly due to rounding. Differences occur in the sum of the rural and urban length and the total report for networks with special-purpose roads.
            </Td>
            <Td>
              Sourced from
              {' '}
              <Link href="https://www.nzta.govt.nz/planning-and-investment/learning-and-resources/transport-data/data-and-tools/" isExternal>
                NZTA Data and Tools
              </Link>
              .
            </Td>
          </Tr>

          <Tr>
            <Td><strong>Total Cycleways (km)</strong></Td>
            <Td>
              The length of the cycleway network including both urban and rural.
            </Td>
            <Td>
              Sourced from
              {' '}
              <Link href="https://www.nzta.govt.nz/assets/userfiles/transport-data/PSCycleways.xlsx" isExternal>
                NZTA Data and Tools, physical statistics - cycleways
              </Link>
              .
            </Td>
          </Tr>

          <Tr>
            <Td><strong>No. of bridges</strong></Td>
            <Td>
              The total number of bridges at the end of the financial year. Reported to NZTA by the Territorial Authority for annual reporting.
            </Td>
            <Td>
              Sourced from
              {' '}
              <Link href="https://www.nzta.govt.nz/planning-and-investment/learning-and-resources/transport-data/data-and-tools/" isExternal>
                NZTA Data and Tools
              </Link>
              .
            </Td>
          </Tr>

          <Tr>
            <Td><strong>Funding Assistance Rate (FAR)</strong></Td>
            <Td>
              The NZTA normal funding assistance rate for Territorial Authority co-investment for the reported financial year.
            </Td>
            <Td>
              Sourced from
              {' '}
              <Link href="https://www.nzta.govt.nz/planning-and-investment/planning-and-investment-knowledge-base/202124-nltp/202124-nltp-funding-assistance-rates/funding-assistance-rates-for-the-2021-24-national-land-transport-programme/normal-funding-assistance-rates/" isExternal>
                NZTA published normal funding assistance rates
              </Link>
              .
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  );
}

export default SourcesTable;
