import { useEffect, useState } from 'react';
import { Text, Center, Box } from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiMinus } from 'react-icons/fi';
import { TrafficLightColours as COLOURS } from '../charts/ChartColours';

function Indicator({ marker, colour }) {
  if (marker === 'up') {
    return (<FiChevronUp fontSize={32} color={colour} />);
  }
  if (marker === 'down') {
    return (<FiChevronDown fontSize={32} color={colour} />);
  }
  if (marker === 'flat') {
    return (<FiMinus fontSize={32} color={colour} />);
  }
  return null;
}

function HeaderTrendIndicator({
  queryInfo, fontSize, postfix, precision,
}) {
  const [value, setValue] = useState('--');
  const [marker, setMarker] = useState('none');
  const [colour, setColour] = useState(COLOURS.GREY);

  let digits = 1;
  if (precision !== null) {
    digits = precision;
  }
  useEffect(() => {
    if ('value' in (queryInfo?.data ?? {})) {
      const result = queryInfo.data;
      if (!result) {
        setColour(COLOURS.GREY);
        setMarker('none');
        setValue('No data');
      } else {
        setColour(COLOURS[result.colour]);
        if (result.value === null || result.value === '--') {
          setValue('No data');
        } else {
          const formattedText = result.value.toLocaleString('en-NZ', { minimumFractionDigits: digits, maximumFractionDigits: digits });
          setValue(`${formattedText}${postfix || ''}`);
        }
        setMarker(result.marker);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo.data]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Box>
      <Center>
        <Text
          color={colour}
          fontSize={fontSize || 32}
          textAlign="center"
        >
          {value}
        </Text>
        <Indicator marker={marker} colour={colour} />
      </Center>
    </Box>
  );
}

export default HeaderTrendIndicator;
