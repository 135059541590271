import { Text } from '@chakra-ui/react';
import Widget from './Widget';
import { useNetworkAvailabilityKPI } from '../../context/reports-api-hooks';
import { CircleIndicator } from '../Indicators';
import { roundKpiValue } from '../../shared/helpers';

export default function NetworkAvailabilityAccessibilityWidget() {
  const { data: networkAvailabilityData, isLoading, isError } = useNetworkAvailabilityKPI();

  const title = 'Network Availability\n& Accessibility';
  const description = 'Proportion of network accessible to\nClass 1 and 50MAX vehicles';

  // If there is an error and no data we want to hide the widget
  if (isError && !networkAvailabilityData) return null;

  return (
    <Widget
      link="/performance/reports/network-availability"
      title={title}
      description={description}
      lockYear={networkAvailabilityData?.lockYear}
      quarter={null}
      isLoading={isLoading && !networkAvailabilityData}
      moreInfoContent={
        <Text fontSize="sm">The proportion of the road network that is accessible to Class 1, 50MAX, and HPMV heavy vehicles.</Text>
      }
    >
      <CircleIndicator
        size={100}
        colour={networkAvailabilityData?.value !== undefined ? 'gray.700' : null}
        value={networkAvailabilityData?.value !== undefined ? `${roundKpiValue(networkAvailabilityData?.value, 99.5, 1, 'min')}%` : undefined}
        isLoading={isLoading || !networkAvailabilityData}
      />
    </Widget>
  );
}
