import {
  Box,
  Container,
  Flex, Text,
  Wrap,
} from '@chakra-ui/react';
import MainLayout from '@src/components/layouts/main/MainLayout';
import {
  useBridgesCount,
  useCyclewaysNetworkLength,
  useNetworkCharacteristicsKPI,
  useRoadsNetworkLength, useRoadsUrbanLength,
} from '@src/modules/performance/context/reports-api-hooks';
import { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination';
import ReportCard from '../../components/ReportCard';
import InfoContentCollapse from './components/information/InfoContentCollapse';
import InfoScroller from './components/information/InfoScroller';
import HeaderTextPercentage from './components/selfloading/HeaderTextPercentage';
import SLLineChart from './components/selfloading/SLLineChart';
import SLStackedLineChart from './components/selfloading/SLStackedLineChart';
import html from './help/NetworkPhysicalCharacteristics.html';

const subheader = 'Roads, Cycleways and Bridges';

const stackedColours = ['transblue', 'transgrey'];
const lineColours = [
  'blue',
  'orangered',
  'grey',
];

function NetworkPhysicalCharacteristics() {
  const { pathname } = useLocation();
  const reportUrl = pathname.split('/').pop();

  const networkCharacteristicsKPI = useNetworkCharacteristicsKPI();
  const roadsNetworkLength = useRoadsNetworkLength();
  const roadsUrbanLength = useRoadsUrbanLength();
  const cyclewaysNetworkLength = useCyclewaysNetworkLength();
  const bridgesCount = useBridgesCount();
  // this controls the expansion of the help section
  const [index, setIndex] = useState(null);

  const helpSectionRef = useRef(null);
  // triggered when the user clicks on the help icon in the title
  const expandHelpSection = () => {
    setIndex(0);
  };

  return (
    <MainLayout backgroundColor="gray.50">
      <Container maxW="full">
        <Pagination currentReportUrl={reportUrl} />
        <ReportCard mt={2} pt={0}>
          <ReportCard.Header
            title="Network Physical Characteristics"
            subtitle={subheader}
            info={<InfoScroller expandHelpSection={expandHelpSection} ref={helpSectionRef} />}
          >
            <Flex align="center">
              <Box mr={3}>
                <HeaderTextPercentage
                  queryInfo={networkCharacteristicsKPI}
                  precision={1}
                  colour="black"
                  postfix="km"
                />
              </Box>
              <Text fontWeight="bold" color="gray">
                Network Length Growth
                <br />
                <Text as="span" fontSize="xs" color="gray">
                  (
                  {networkCharacteristicsKPI?.data?.averageYearRange}
                  {' '}
                  Annual Avg)
                </Text>
              </Text>
            </Flex>
          </ReportCard.Header>
          <ReportCard.Body>
            <Wrap spacing={4}>
              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Roads</Text>
                <Flex alignContent="start" className="gap">
                  {/* two charts */}
                  <SLStackedLineChart
                    queryInfo={roadsNetworkLength}
                    title1="Network length (km)"
                    title2="&nbsp;"
                    labels={[
                      'Sealed',
                      'Unsealed',
                    ]}
                    colours={stackedColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                  <SLLineChart
                    queryInfo={roadsUrbanLength}
                    title1="Urban % by length"
                    title2="&nbsp;"
                    labels={['Urban']}
                    colours={lineColours}
                    unitprefix=""
                    unitpostfix="%"
                    hideLegend
                    chartMin={0.0}
                    chartMax={100.0}
                  />
                </Flex>
              </Box>

              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Cycleways</Text>
                <Flex alignContent="start" className="gap">
                  {/* one chart */}
                  <SLStackedLineChart
                    queryInfo={cyclewaysNetworkLength}
                    title1="Network length (km)"
                    title2="&nbsp;"
                    labels={[
                      'Rural',
                      'Urban',
                    ]}
                    colours={stackedColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                </Flex>
              </Box>

              <Box>
                <Text mb={5} textStyle="reportSectionHeader">Bridges</Text>
                <Flex alignContent="start" className="gap">
                  {/* one chart */}
                  <SLLineChart
                    queryInfo={bridgesCount}
                    title1="No. bridges"
                    title2="&nbsp;"
                    labels={['Total', 'Timber', 'Single lane']}
                    colours={lineColours}
                    unitprefix=""
                    unitpostfix=""
                  />
                </Flex>
              </Box>
            </Wrap>
          </ReportCard.Body>
        </ReportCard>
        <InfoContentCollapse
          ref={helpSectionRef}
          title="Information Sources"
          html={html}
          index={index}
          setIndex={setIndex}
        />
      </Container>
    </MainLayout>
  );
}

export default NetworkPhysicalCharacteristics;
