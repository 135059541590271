import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';

function HeaderTextPercentage({
  queryInfo, colour, prefix, postfix, precision, precisionPercentage, formatK,
}) {
  const [text, setText] = useState('No data');

  useEffect(() => {
    if (queryInfo?.data) {
      const { data: result } = queryInfo;
      if (result.nodata) {
        setText('No data');
      } else {
        let t = result.value;

        let digits = 1;
        if (precision !== null) {
          digits = precision;
        }

        let actualPostfix = postfix;
        // display large numbers in rounded k's
        if (formatK && t > 10000) {
          t /= 1000;
          digits = 0;
          actualPostfix = 'k';
        }

        const formattedText = t.toLocaleString('en-NZ', { minimumFractionDigits: digits, maximumFractionDigits: digits });

        setText(`${prefix || ''}${formattedText}${actualPostfix || ''} (${result.percentage.toFixed(precisionPercentage || 1)}%)`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Text mr={3} fontSize={32} color={colour}>{text}</Text>
  );
}

export default HeaderTextPercentage;
