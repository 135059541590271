import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import { roundKpiValue } from '@src/modules/performance/shared/helpers';

function HeaderText({
  queryInfo, colour, prefix, postfix, precision,
}) {
  const [text, setText] = useState('No data');

  useEffect(() => {
    if (queryInfo?.data) {
      const result = queryInfo.data;
      if (result.nodata) {
        setText('No data');
      } else {
        setText(`${prefix || ''}${roundKpiValue(result.value, 5, precision)}${postfix || ''}`);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInfo]);

  if (!queryInfo?.data) {
    return null;
  }

  return (
    <Text mr={3} fontSize={32} color={colour}>{text}</Text>
  );
}

export default HeaderText;
