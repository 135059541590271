import { Bar } from 'react-chartjs-2';
import { ChartColours as COLOURS } from './ChartColours';

/* this chart has a bit of kludging in it to ensure
  the blue line is displayed (some distance) away from the x axis. It has to 'float'
*/
function DoubleAxisBarLineChart({
  grouping, datasets, colours, labels, vertlabels, unitprefix, unitpostfix, precision, width, chartType,
}) {
  const height = 250;
  const chartWidth = width || 600;

  let fixedPrecision = 1;
  if (precision !== null) {
    fixedPrecision = precision;
  }

  const data = {
    // grouping on x axis
    labels: grouping.map((label) => {
      // auto split the grouping labels by whitespace,
      // so we get multiline labels and the chart looks nicer
      if (/\s/.test(label)) {
        return label.split(' ');
      }
      return label;
    }),
    datasets: [],
  };

  let suggestedMin = 0;

  // populate the datasets
  datasets.forEach((ds, index) => {
    // find the min value of this data
    const minScore = Math.min(...ds.data);

    switch (chartType[index]) {
      case 'line':
        data.datasets.push({
          type: chartType[index],
          label: labels[index],
          data: ds.data,
          borderColor: COLOURS[colours[index]], // line colour
          backgroundColor: COLOURS[colours[index]],
          yAxisID: ds.id,
          pointRadius: 2, // hide the dots
          borderWidth: 2, // thickness of the line
          order: 1,
        });
        suggestedMin = minScore > 0 && unitpostfix.includes('%') ? 0 : minScore;
        // this ensures we get a bit of space below the line
        // bit futzy - not sure how well this holds up
        if (suggestedMin > 2) {
          suggestedMin = 2 * Math.round((suggestedMin - (suggestedMin * 0.02)) / 2);
        } else {
          suggestedMin = Math.floor((suggestedMin * 10)) / 10;
        }
        if (suggestedMin >= minScore) {
          suggestedMin = 2 * Math.round((minScore - (minScore * 0.05)) / 2);
        }
        if (suggestedMin >= minScore) {
          suggestedMin = 2 * Math.round((minScore - (minScore * 0.1)) / 2);
        }

        break;
      case 'bar':
        data.datasets.push({
          type: chartType[index],
          label: labels[index],
          data: ds.data,
          backgroundColor: COLOURS[colours[index]],
          yAxisID: ds.id,
          categoryPercentage: 0.9, // this controls the width of the categories - not full width so gap
          barPercentage: 1.0, // this controls the width of the bars within a category - full width, so no gap
          order: 2,
        });
        break;
      default:
        break;
    }
  });

  const options = {
    responsive: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (tooltipItem) => {
            let axisId = 0;
            if (tooltipItem.dataset.yAxisID !== 'left') {
              axisId = 1;
            }
            // remove decimal precision here, can't have partial people ;)
            let p = fixedPrecision;
            if (unitpostfix[axisId] === '') {
              p = 0;
            }
            return `${tooltipItem.dataset.label}: ${unitprefix[axisId]}${parseFloat(tooltipItem.raw || 0).toFixed(p)}${unitpostfix[axisId]}`;
          },
          title: (tooltipItem) => {
            let l = tooltipItem[0].label;
            if (l.indexOf(',') > -1) {
              l = l.replace(',', ' ');
            }
            return l;
          },
        },
      },
      legend: {
        labels: {
          usePointStyle: true, // round dots on legend
          boxWidth: 4, // size of the dots
          boxHeight: 4,
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      // note:
      // these scales need to have same names as ids.
      // calling them anything else results in duplicate scale bars
      left: {
        position: 'left', // y-axis on the right
        display: true,
        ticks: {
          precision: fixedPrecision, // no decimals on y axis
          padding: 10, // spacing between ticks and y-values
          beginAtZero: true,
          callback: (value) => `${unitprefix[0]}${value}${unitpostfix[0]}`, // add units to y axis values

        },
        grid: {
          display: true,
          drawBorder: false, // hide the y-axis bar
          drawOnChartArea: true, // draw the grid lines
          drawTicks: false,
        },
        title: {
          display: true,
          text: vertlabels[0],
        },
      },
      right: {
        position: 'right', // y-axis on the left
        display: true,
        min: suggestedMin, // bit of futzing to auto calculate a nice minimum for this chart
        ticks: {
          precision: fixedPrecision, // no decimals on y axis
          padding: 10, // spacing between ticks and y-values
          beginAtZero: false,
          callback: (value) => `${unitprefix[1]}${value}${unitpostfix[1]}` // add units to y axis values
          ,
        },
        grid: {
          drawBorder: false, // hide the y-axis bar
          display: false,
        },
        title: {
          display: true,
          text: vertlabels[1],
        },
        // grace: '-5%'
      },
      x: {
        grid: {
          display: false, // hide the vertical grid lines
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        ticks: {
          font: {
            size: 12,
          },
        },
      },
    },
  };

  return (
    <Bar data={data} options={options} height={height} width={chartWidth} />
  );
}

export default DoubleAxisBarLineChart;
